{
  "FUSIONAUTH_AUTHORITY": "https://auth.sitemate.com",
  "NODE_ENV": "mobile",
  "GLOBAL_API_URL": "https://api-global-mobile.hseqai.com/",
  "SITEMATE_API_URL": "https://api-mobile.hseqai.com/",
  "FUSIONAUTH_FLOWSITE_APPLICATION_ID": "c726bcce-4392-4cb7-93b1-716a7dfb7934",
  "FUSIONAUTH_DASHPIVOT_APPLICATION_ID": "cd0685a0-78d9-4d42-9d74-5658bd70d3c5",
  "DASHPIVOT_CALLBACK_PATH": "/oauth-callback/dashpivot",
  "XERO_CLIENT_PKCE": "945FE0BB63E54FCEB0BDAA8EBB5A974D",
  "XERO_CALLBACK_PATH": "/oauth-callback/xero",
  "QUICKBOOKS_CLIENT_ID": "ABo501AhhYUTvvff4DefklS5GcRmqw7lFxPq3EL4RJvWhZ1I4h",
  "QUICKBOOKS_CALLBACK_PATH": "/oauth-callback/quickbooks",
  "MICROSOFT_CLIENT_ID": "e1b77bf4-41b8-4285-a516-a3d6ba48c26f",
  "MICROSOFT_CALLBACK_PATH": "/oauth-callback/microsoft"
}
